@import "../../../core/config/styles/theme.less";
@import "../../main.less";
.logoutOverlayBlock {
  width: 320px;
  min-height: 120px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.navbar-header .navbar-header-end-div {
  width: -moz-available !important;
}
.profile-dropdown-info {
  border-bottom: 1px solid #dfe2e7;
  h3 {
    font-weight: 600;
    color: #14151c;
  }

  h4 {
    font-weight: 600;
    color: #14151c;
    padding: 8px 0;
    cursor: pointer;
  }
}

.profileUser {
  width: 24px;
  height: 24px;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  color: #4d5165;
  border: 1px solid #e8eaed;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px 3px;
  font-weight: 600;
  background: #f2f4f6;
  float: left;
}

.profile-dropdown-delete-account {
  padding: 12px 15px 5px 15px;
  cursor: pointer;
  h4 {
    color: #cf3537;
    font-weight: 500;
  }
}

.profile-dropdown-logout {
  padding: 0px 15px 9px 15px;
  cursor: pointer;
  h4 {
    color: #696f88;
    font-weight: 500;
  }
}

.profile-dropdown-info .@{ant-prefix}-radio-button-wrapper {
  cursor: pointer;
}

.syt-antd-dropdown-hidden {
  display: none;
}

.appLogo {
  padding: 0px 2px 0px 10px;
  margin-bottom: 10px;
}

.app-logo-divider {
  height: 24px;
  width: 1px;
}

.app-logo-title {
  padding: 0px;
  margin-bottom: 10px;
}

.navbar-header {
  border: 1px solid #dfe2e7;
}

.navbar-header .navbar-header-title {
  margin: 4px 8px 0px 0px;
  color: #dfe2e7;
}

.edit-profile-link {
  padding-top: 10px;
  cursor: pointer;
}

.profile-navigation-divider {
  margin: 0px;
  border-color: rgba(5, 5, 5, 0.06);
}

.profile-navigation-menu-title {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 0px !important;
  color: #14151c !important;
}

.mf-elements-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.syt-antd-drawer-close {
  padding: 0px;
}

.navbar-settings {
  .group {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }

  .navbar-setting-group {
    color: #14151c;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #dfe2e7;
  }

  .navbar-farm-setting {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 4px;
    margin-bottom: 12px;
    border-radius: 4px;
    transition: background-color 400ms ease-in-out;
    border: none;
    background: none;
    text-decoration: none;
    color: #14151c;
    font-family: "Noto Sans", sans-serif;
    cursor: pointer;

    svg {
      color: #696f88;
      transition: transform 400ms ease-in-out;
      margin-left: 4px;
      font-size: 12px;
    }
  }

  .navbar-settings-btn-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .navbar-setting-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    transition: background-color 400ms ease-in-out;
    color: #14151c;
    border: none;
    background: none;
    text-decoration: none;
  }

  .navbar-setting-btn:hover {
    color: #14151c;
    background-color: #eaf6ff;

    .setting-btn-icon {
      background-color: #c6e6ff;
    }

    .setting-open-icon {
      opacity: 1;
    }
  }

  .setting-open-icon {
    margin: 0 0 0 auto;
    padding: 0 6px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .setting-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #eaf6ff;
    transition: background-color 400ms ease-in-out;
    flex-shrink: 0;
  }

  .navbar-setting-title {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    text-align: start;
    font-family: "Noto Sans", sans-serif;
  }

  .farm-settings-title {
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #14151c;
  }
}

.syt-antd-layout-sider-collapsed {
  max-width: 0px !important;
  min-width: 0px !important;
  width: 0px !important;
}

.mf-logo-sec {
  .syt-antd-space-item {
    height: 25px;
  }
}

.language-selection-menu {
  width: 100%;
  border-right: none;

  .syt-antd-menu-submenu:hover
    > .syt-antd-menu-submenu-title
    > .syt-antd-menu-submenu-arrow {
    color: #696f88;
  }

  .syt-antd-menu-submenu
    > .syt-antd-menu-submenu-title
    > .syt-antd-menu-submenu-arrow {
    color: #696f88;
    padding-left: 12px;
  }

  .custom-language-menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 35px;
    max-height: 40px;

    &:hover {
      color: #696f88;
    }

    .custom-language-label {
      font-size: 14px;
      color: #14151c;
      margin-bottom: 0;
    }
  }

  .syt-antd-menu-submenu-title {
    padding: 0;
    color: #696f88;

    &:hover {
      color: #696f88;
    }
  }
}

.language-submenu {
  .syt-antd-menu {
    li[role="menuitem"] {
      display: flex;
      align-items: center;
      color: #14151c;
      height: 32px;

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &.syt-antd-menu-item-selected {
        font-weight: bold;

        &::before {
          content: "";
          height: 0;
          width: 0;
        }
      }
    }
  }
}

.email-popup-title {
  font-family: @font_family_noto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.0075em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
