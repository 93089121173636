@import "../../shared/main.less";
@media only screen and (max-width: 992px) {
  .insights-container {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .insights-sprayclock-containter {
    display: block !important;
  }
  .spray-clock-container {
    width: 100% !important;
  margin-left: 0px !important;
  }
  .insights-button {
    margin: 10px !important;
    width: 90% !important;
  }
}
@media only screen and (max-width: 768px) {
  .insights-container {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .insights-sprayclock-containter {
    display: block !important;
  }
  .spray-clock-container {
    width: 100% !important;
  margin-left: 0px !important;

  }
}
@media only screen and (max-width: 576px) {
  .insights-container {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .insights-sprayclock-containter {
    display: block !important;
  }
  .spray-clock-container {
    width: 100% !important;
  margin-left: 0px !important;
  }
}
.mapview-container {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.welcome-title-text {
  font-family: @font_family_noto;
  font-weight: 700;
  font-size: 24px;
  line-height: 32.69px;
  letter-spacing: 0%;
}
.sub-tile-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}
.insights-sprayclock-containter {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}
.insights-container {
  border-radius: 10px;
  width: 48%;
  background: #F3F4F6;
  box-shadow: 2px 1px 5.1px 0px #0000000A;
}
.insights-image {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.insights-title {
  font-family: @font_family_noto;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.75%;
  color: @color_black;
}
.insights-sub-title {
  font-family: @font_family_noto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #464646;
  margin-top: 5px;
}
.insights-grouping {
  padding: 10px;
}
.insights-button {
  margin-top: 10px;
  width: 100%
}
.circle-right-div {
  display: none !important;
}
.spray-clock-container {
  border-radius: 10px 10px 0 0;
  width: 48%;
  margin-left: 10px;
}
.spray-clock-des {
  font-family: @font_family_noto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  color: #464646;
  margin-top: 5px;
  margin-left: 5px;
}
.spray-clock-button {
  background-color: #00004B !important;
  width: 95%;
  margin: 10px;
  color: @color_white !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
